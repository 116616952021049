import React from "react";

const Footer = () => {
    return (
        <footer class="bg-[#2C3147] dark:bg-gray-900" id="footer">
            <div class="mx-auto w-full max-w-screen-xl p-4 py-12 lg:py-8">
                <div class="md:flex md:justify-between">
                    <div class="mb-6 md:mb-0">
                        <a href="https://www.linkedin.com/company/flexinovus/" target="_blank" rel="noreferrer" class="flex flex-col items-center">
                            <img src={require("../Assets/Nav_logo.png")} class="h-32" alt="Flexinovus Logo" />
                            <div className="mt-6" style={{ "width": "10%", "height": "4vh" }} ><img src={require("../Assets/linkedin.png")} style={{ "width": "100%", "height": "100%" }} alt="" /></div>
                        </a>
                    </div>
                    <div class="grid grid-cols-2 gap-4 sm:gap-6 sm:grid-cols-3">
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Solutions</h2>
                            <ul class="text-gray-500 text-white font-medium">
                                <li>
                                    <a href="/" class="hover:underline">Omni Channel Order Management</a>
                                </li>
                                <li>
                                    <a href="/" class="hover:underline">Delivery Fullfillment Strategies</a>
                                </li>
                                <li>
                                    <a href="/" class="hover:underline">Warehouse Management</a>
                                </li>
                                <li>
                                    <a href="/" class="hover:underline">Logistics</a>
                                </li>
                                <li>
                                    <a href="/" class="hover:underline">Discrete Manfacturing</a>
                                </li>
                                <li>
                                    <a href="/" class="hover:underline">Inventory Visiblity and Optimization</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Resources</h2>
                            <ul class="text-gray-500 text-white font-medium">
                                <li>
                                    <a href="/" class="hover:underline">Documentation</a>
                                </li>
                                <li>
                                    <a href="/" class="hover:underline">Articles</a>
                                </li>
                                <li>
                                    <a href="/" class="hover:underline">Supply Chain</a>
                                </li>
                                <li>
                                    <a href="/" class="hover:underline">Certifications</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Company</h2>
                            <ul class="text-gray-500 text-white font-medium">
                                <li>
                                    <a href="/" class="hover:underline">About Us</a>
                                </li>
                                <li>
                                    <a href="/" class="hover:underline">Blogs</a>
                                </li>
                                <li>
                                    <a href="/" class="hover:underline">Contact Us</a>
                                </li>
                                <li>
                                    <a href="/" class="hover:underline">Join Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end items-center w-full">
                    <img src={require("../Assets/madeindia.png")} className=" w-24" alt="" />
                </div>
                <hr class="my-0 border-gray-200 sm:mx-auto dark:border-gray-700" />
                <div class="sm:flex sm:items-center sm:justify-between">
                    <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2021 Flexinovus. All Rights Reserved.
                    </span>
                    <div class="flex mt-4 sm:justify-center sm:mt-0">
                        <a href="/" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                            <p>Term Of Use</p>
                        </a>
                        <a href="/" class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
                            <p>Privacy Policy</p>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
