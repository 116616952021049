import React from "react";

const cardData = [
  {
    title: "Praduman Jain",
    points: [
      "Praduman has 20 plus years of industry experience in IT and Manufacturing sectors. He has worked with esteemed organisations like HCL Technologies, Sapient-Razorfish, PublicisSapient and VIP Industries. He was also a core team member of a successful start-up (Expicient Inc) which was later acquired by Publicis Groupe. Praduman comes with core experience in Supply chain, IT delivery and consulting, Product development and implementations.",
    ],
    img: require("../Assets/praduman.png"),
    designation: "CEO",
    linkedinLink: "https://www.linkedin.com/in/praduman-jain-4537233/"
  },
  {
    title: "Darpan Seth",
    points: [
      "Darpan is currently the Founder and CEO of Nextuple Inc, an entrepreuner and angel investor who has over 20 years of Retail Consumer Goods & Supply Chain experience. Prior to Nextuple, he successfully bootstraped Expicient Inc, a global professional services, a firm that got acquired by the Publicis Groupe. In the past Darpan has worked for IBM, Cambridge Technology Partners and HCL-HP in various client services and product management roles.",
    ],
    img: require("../Assets/darpan.png"),
    designation: "Chief Advisor",
    linkedinLink: "https://www.linkedin.com/in/darpanseth/"
  },
  {
    title: "Manish Puri",
    points: [
      "Manish has over 18 years of industry experience in IT specialising in Retail and Supply Chain. Prior to Flexinovus, he was positioned as Senior Solutions Architect with PublicisSapient and Expicient Inc (later acquired by Publicis Groupe). He has rich Consulting and Order Management experience in implementing Omni-Channel software products and solutions for clients across geographies. In the past, Manish has worked for Headstrong (now Genpact).",
    ],
    img: require("../Assets/ManishPuri-circle.png"),
    designation: "CTO",
    linkedinLink: ""
  },
];

const Card = ({ title, points, img, designation, linkedinLink }) => (
  <div className=" bg-[#D6F3F6] overflow-hidden m-4 shadow border-black p-4 md:w-[40%] lg:w-[25%] md:h-[620px] rounded-md">
    <div className=" flex flex-col justify-center items-center">
      <img
        className="w-15 h-15 object-cover object-center"
        src={img}
        alt={title}
      />
      <div className=" flex items-center relative">
        <h2 className="text-gray-800 text-lg font-bold">{title}</h2>
        <a href={linkedinLink} target="_blank" rel="noreferrer">
          <img
            src={require("../Assets/linkedin.png")}
            alt=""
            className="absolute right-[-30px]"
          />
        </a>
      </div>
      <p>{designation}</p>
    </div>
    <hr class="border-[#2C3147] sm:mx-auto dark:border-gray-700 lg:mt-4 lg:mb-4" />
    <div className="p-px">
      <p className="text-justify">{points}</p>
    </div>
  </div>
);

const Team = () => {
  return (
    <section id="team">
      <div class="text-center">
        <h1 className="text-3xl font-black text-gray-700 my-7">
          Meet the leadership team
        </h1>
      </div>
      <div className="mt-5 hidden md:block flex flex-row items-center relative flex-wrap overflow-hidden">
        <div className=" flex justify-center items-center">
          <div className="mt-5 flex flex-col items-center sm:justify-center relative right-[12%] z-10">
            <div className="mx-auto flex justify-center items-center flex-wrap w-11/12">
              {cardData.map((card, index) => (
                <Card
                  key={index}
                  title={card.title}
                  points={card.points}
                  img={card.img}
                  designation={card.designation}
                  linkedinLink={card.linkedinLink}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="pt-14 px-16 bg-[#E0F4EC] hidden md:block rounded-3xl h-5/6 container mx-auto lg:absolute top-14 left-[70%] ">
          <img
            src={require("../Assets/Team work-amico.png")}
            alt=""
            className="w-80 h-5/6"
          />
        </div>
      </div>
      <div className="md:hidden">
        <div className="mx-auto flex justify-center items-center flex-wrap w-11/12">
          {cardData.map((card, index) => (
            <Card
              key={index}
              title={card.title}
              points={card.points}
              img={card.img}
              designation={card.designation}
              linkedinLink={card.linkedinLink}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
