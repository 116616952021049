import React from "react";

const cardData = [
  {
    title: "Domain Solutions",
    points: ["Omni Channel Order Management", "Delivery fulfillment Strategies", "Inventory Visibility", "Inventory Optimization techniques", "Warehouse Management", "Logistics", "Discrete Manufacturing"],
    img: require("../Assets/cleanup_9767501.png")
  },
  {
    title: "Product Implementation",
    points: ["Agile Product Management", "Program Management", "Expertise in IBM sterling OMS", "Product Development", "Integration and testing Services", "Performance management"],
    img: require("../Assets/implementation_6020485.png")
  },
  {
    title: "Product Support",
    points: ["Product upgrades", "Product Enhancement", "Support and Maintainence", "DevOps"],
    img: require("../Assets/support_6533091.png")
  },
]

const Card = ({ title, points, img }) => (
  <div className=" bg-[#D6F3F6] overflow-hidden m-4 w-[80%] lg:w-[300px] h-[400px] shadow-2xl p-4">
    <div className=" flex items-center">
      <img className="w-12 h-12 object-cover object-center mr-5" src={img} alt={title} />
      <h2 className="text-gray-800 text-lg font-semibold">{title}</h2>
    </div>
    <div className="p-4">

      {points.map((point, index) => (
        <ul className="mt-2 text-gray-600">
          <li key={index} className="flex items-baseline">
            •&nbsp;
            <span>{point}</span>
          </li>

        </ul>
      ))}
    </div>
  </div>
);

const Services = () => {
  return (
    <section id="services">
      <div class="text-center">
        <h1 className="text-3xl font-black text-gray-700 my-7">Our Services</h1>
      </div>

      <div className="mt-5 flex flex-row items-center relative flex-wrap overflow-hidden">
        <div className="p-8 px-16 bg-[#E0F4EC] hidden md:block rounded-3xl">
          <img src={require("../Assets/services.png")} alt="" className=" w-80" />
        </div>
        <div>
          <div className="container mx-auto flex justify-center lg:absolute top-14 left-40 flex-wrap">
            {cardData.map((card, index) => (
              <Card key={index} title={card.title} points={card.points} img={card.img} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
