import React from "react";
import { NavLink } from "react-router-dom";
function Blog() {
  return (
    <section id="blog">
      <div class="relative bg-transparent px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28">
        <div class="absolute inset-0">
          <div class="h-1/3 bg-transparent sm:h-2/3"></div>
        </div>
        <div class="relative mx-auto max-w-7xl">
          <div class="text-center">
            <h1 className="text-3xl font-black text-gray-700 my-7">Our Blogs</h1>
          </div>
          <div class="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
            {/* <div class="flex flex-col overflow-hidden rounded-lg shadow-lg">
              <div class="flex-shrink-0">
                <img class="h-48 w-full object-cover" src={require("../Assets/b3.png")} alt="" />
              </div>
              <div class="flex flex-1 flex-col justify-between bg-white p-6">
                <div class="flex-1 mt-2 justify-center items-center flex-coloumn">
                  <p class="text-xl font-semibold text-gray-900">Is AI Answer to our Complex minds?</p>
                  <p class="mt-3 text-base text-gray-500">By Flexinovus</p>
                  <button>Click Here</button>
                </div>
              </div>
            </div> */}

            <div class="flex flex-col overflow-hidden rounded-lg shadow-lg">
              <div class="flex-shrink-0">
                <img class="h-48 w-full object-cover" src={require("../Assets/b3.jpg")} alt="" />
              </div>
              <div class="flex flex-1 flex-col justify-between bg-white p-6">
                <div class="flex-1 mt-2 justify-center items-center flex-coloumn">
                  <p class="text-xl font-semibold text-gray-900">The Crucial Role of Estimated Delivery Dates in E-Commerce Post-COVID</p>
                  <p class="mt-3 text-base text-gray-500">By Flexinovus | 2 MIN READ</p>
                  <NavLink to="/blog" state={{ from: "The-Crucial-Role" }}>Click Here</NavLink>
                </div>
              </div>
            </div>

            <div class="flex flex-col overflow-hidden rounded-lg shadow-lg">
              <div class="flex-shrink-0">
                <img class="h-48 w-full object-cover" src={require("../Assets/AI.jpg")} alt="" />
              </div>
              <div class="flex flex-1 flex-col justify-between bg-white p-6">
                <div class="flex-1 mt-2 justify-center items-center flex-coloumn">
                  <p class="text-xl font-semibold text-gray-900">Is AI Answer to our Complex minds?</p>
                  <p class="mt-3 text-base text-gray-500">By Flexinovus | 5 MIN READ</p>
                  <NavLink to="/blog" state={{ from: "Is-AI-Answer" }}>Click Here</NavLink>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog;
