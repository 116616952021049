import React from "react";
import Hero from "./Hero";
import Services from "./Services";
import Partnership from "./Partnership";
import Blog from "./Blog";
// import Footer from "./Footer";
import Team from "./Team";
import ClientCarousel from "./Pause";
import NewsLetter from "./NewsLetter";
import Footer from "./Footer/Footer";

const Home = () => {

  return (
    <div className="bg-[#caecdc] overflow-hidden">
      <Hero />
      <br />
      <Services />
      <Partnership />
      <br />
      <Team />
      <Blog />
      {/* <Footer /> */}
      <Footer />
    </div>
  );
};

export default Home;
