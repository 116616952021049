import React from 'react'

const Hero = () => {
  return (
    <section class="bg-gradient-to-r from-[#23af6c] to-blue-400">
        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl mb-6 font-extrabold text-gray-700 lg:mb-8 md:text-lg lg:text-3xl">
            One cannot alter a condition with the same mindset that created it in the first place.
            </h1>
          </div>
          <div class="hidden lg:mt-6 lg:col-span-5 lg:flex">
            <img src={require("../Assets/Innovation-amico.png")} alt="mockup" />
          </div>
        </div>
      </section>
  )
}

export default Hero