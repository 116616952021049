/* eslint-disable */
import React from 'react';
import './Footer.css';
import NewsLetter from '../NewsLetter';

const Footer = () => (
  <div className="app__footer" id="footer">
    <NewsLetter />
    <br />
    <footer class="w-[100%] bg-[#2C3147] dark:bg-gray-900">
      <div class="mx-auto w-full max-w-screen-xl p-4 py-12 lg:py-8">
        <div class="md:flex md:justify-around">
          <div >
            <a href="https://www.linkedin.com/company/flexinovus/" target="_blank" rel="noreferrer" class="flex flex-col items-center">
              <img src={require("../../Assets/Nav_logo.png")} class="h-32" alt="Flexinovus Logo" />
              <div className="mt-6" style={{ "width": "10%", "height": "4vh" }} ><img src={require("../../Assets/linkedin.png")} style={{ "width": "100%", "height": "100%" }} alt="" /></div>
            </a>
          </div>
          <div>
            <div className="app__footer-links_contact">
              <h1 className="app__footer-headtext">Contact Us</h1>
              <p className="p__opensans">contactus@flexinovus.com</p>
              <p className="p__opensans">+91 9811051108</p>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center w-full">
          <img src={require("../../Assets/madeindia.png")} className=" w-24" alt="" />
        </div>
        <hr class="my-0 border-gray-200 sm:mx-auto dark:border-gray-700" />
        <div class="sm:flex sm:items-center sm:justify-between">
          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2021 Flexinovus. All Rights Reserved.
          </span>
          <div class="flex mt-4 sm:justify-center sm:mt-0">
            <a href="/" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
              <p>Term Of Use</p>
            </a>
            <a href="/" class="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
              <p>Privacy Policy</p>
            </a>
          </div>
        </div>
      </div>
    </footer>

  </div>
);

export default Footer;
