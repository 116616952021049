/* eslint-disable */
import axios from 'axios';
import React, { useState } from 'react';

function NewsLetter() {
    const formid = "1rdU9TwMQ";
    const formURL = `https://submit-form.com/${formid}`;

    const initialFormState = {
        name: "",
        email: "",
        message: "",
    }

    const [message, setMessage] = useState(initialFormState);
    const [loading, setLoading] = useState(false);

    let submitMessage = async (event) => {
        event.preventDefault();
        setLoading(true);
        // console.log(message);
        await postSubmissioin();
        setMessage(initialFormState);
    }

    const postSubmissioin = async () => {
        const payload = {
            ...message,
        };

        try {
            const result = await axios.post(formURL, payload);
            alert("Thanks for reaching out to us. Someone will be in touch shortly.");
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleMessage = (event) => {
        const { id, value } = event.target;
        const formKey = id;
        const updatedFormState = { ...message };
        updatedFormState[formKey] = value;
        setMessage(updatedFormState);
    }

    return (
        <div className="app__newsletter" id="footer">
            {/* <form onSubmit={(event) => submitMessage(event)}> */}
            <div className="app__newsletter-heading">
                {/* <SubHeading title="Reach out to us" /> */}
                <h1 className="text-3xl font-black text-gray-700 my-7">REACH OUT TO US</h1>
            </div>

            <div className="app__newsletter-input flex__center">
                <input type="text" id="name" placeholder="Enter Name" value={message.name} onChange={handleMessage} />
                <input type="email" id="email" placeholder="Enter Email" value={message.email} onChange={handleMessage} />
                <input type="text" id="message" placeholder="Enter Message" value={message.message} onChange={handleMessage} />
                <button disabled={loading} type="button" onClick={submitMessage} class="bg-[#263238] w-[20%] items-center justify-center py-1 text-base font-medium text-center text-white rounded-lg dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">SEND</button>
            </div>
            {/* </form> */}
        </div>
    )
};

export default NewsLetter;
