import React, { useState ,useEffect } from 'react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`bg-[#caecdc] ${isSticky ? 'fixed top-0 w-full z-10' : ''} w-full  z-50`}>
      <div className="container mx-auto p-2 flex items-center justify-between">
        {/* Logo */}
        <div className="text-black text-xl ml-4">
          <img src={require("../Assets/Nav_logo.png")} className='w-44 h-16' alt="" />
        </div>

        {/* Menu on large screens */}
        <div className="hidden lg:flex space-x-4">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/products">Products</NavLink>
          <NavLink to="/#services">Services</NavLink>
          <NavLink to="/#partnership">Partnership</NavLink>
          <NavLink to="/#team">Team</NavLink>
          <NavLink to="/career">Career</NavLink>
          <NavLink to="/#blog">Blog</NavLink>
          <NavLink to="/#footer">Contact Us</NavLink>
        </div>

        {/* Hamburger menu on small screens */}
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-black focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Dropdown menu on small screens */}
      {isOpen && (
        <div className="lg:hidden mt-2">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/products">Products</NavLink>
          <NavLink to="/#services">Services</NavLink>
          <NavLink to="/#partnership">Partnership</NavLink>
          <NavLink to="/#team">Team</NavLink>
          <NavLink to="/career">Career</NavLink>
          <NavLink to="/#blog">Blog</NavLink>
          <NavLink to="/#footer">Contact Us</NavLink>
        </div>
      )}
    </nav>
  );
};

const NavLink = ({ to, children }) => (
  <a href={to} className="block py-2 px-4 text-black hover:bg-gray-300">
    {children}
  </a>
);

export default Navbar;
