import React from "react";
import Footer from "./Footer";

const Career = () => {
  return (
    <div className="bg-[#caecdc] overflow-hidden">
      <section class=" bg-gradient-to-r from-[#23af6c] to-[#08B8C1]">
        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl md:text-2xl lg:text-5xl mb-4 font-extrabold tracking-tight leading-none  dark:text-[#2C3147]">
              Lets make an impact <br />
              Join the team
            </h1>
          </div>
          <div class="hidden lg:mt-6 lg:col-span-5 lg:flex">
            <img src={require("../Assets/careers_covers.png")} alt="mockup" />
          </div>
        </div>
      </section>
      <div class="container w-full mt-10 mb-5 mx-auto px-10">
        <div class="flex flex-col justify-center">
        <h1 className="text-4xl text-center font-bold text-gray-700 my-7">Opportunities</h1>
          <ul class="list-disc pl-4 lg:mx-14">
            <li class="my-2 text-gray-700 font-semibold text-xl">Job opening for technical team members with relevant experience of 2-5 years in IBM Sterling commerce development and support</li>
            <li class="my-2 text-gray-700 font-semibold text-xl">Job openings for Functional/QA team members with relevant experience of 2-5 years in Testing and Automation in Retail domain products like Manhattan, IBM Sterling Commerce, JDA etc</li>
            <li class="my-2 text-gray-700 font-semibold text-xl">Job openings for full stack developers (ReactJS, Angular Node JS, Java Springboot) with 2-5 years of experience</li>
          </ul>
          <p class="my-8 text-xl font-semibold text-gray-700 lg:mx-14">
          Interested candidates can mail their resumes at contactus@flexinovus.com
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Career;
