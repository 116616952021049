import React, { useEffect, useState } from "react";
import "../App.css";
import Footer from "./Footer";

const featuresData = [
  {
    title: "Key Features",
    points: [
      "Fulfilment of both B2B and B2C orders",
      "End to end cycle of fulfilment",
      "Order creation",
      "Order schedule & release",
      "Order shipment",
      "Payment reconciliation",
      "Complete Returns and Exchanges",
      "Inventory visibility across stores",
      "Transfer and addition of inventory",
      "Reorders and backorders replenishments",
    ],
    img: require("../Assets/Key_features.png"),
  },
  {
    title: "Informative Dashboard",
    points: [
      "Intelligent allocation rules for fulfilment",
      "Partial shipments and fulfilment through different nodes",
      "Easy Master Setup of the enterprise",
      "Items defined at Product/Item category level",
      "Purchase Management",
      "Bulk Upload of record and download of reports",
      "Easy creation of Clients (B2B) and Customer(B2C)",
      "Taxation rules configurable based on channels",
      "Minimum Safety Stock and Reorder Qty configurable",
    ],
    img: require("../Assets/dashboard.png"),
  },
  {
    title: "Integrations",
    points: [
      "Major Carts (Shopify, Woo commerce and other webstores)",
      "Marketplaces (Amazon, Flipkart, Myntra)",
      "Shipping and Logistics partner",
      "Warehouse management",
      "ERP systemsFinancial applications",
    ],
    img: require("../Assets/integration.png"),
  },
  {
    title: "Security and Performance",
    points: [
      "Role based access to modules",
      "Data and Application on Cloud (Azure)",
      "Highly secured layer",
      "Scalable and performance driven architecture",
      "Automated deployment (Git-hub and Jenkins)",
    ],
    img: require("../Assets/security.png"),
  },
];

const Feature = ({ title, points, img }) => (
  <div className=" bg-[#D6F3F6] overflow-hidden m-4 w-[90%] lg:w-[500px] h-[100%] shadow-2xl p-4">
    <div className=" flex items-center">
      <img
        className="w-12 h-12 object-cover object-center mr-5"
        src={img}
        alt={title}
      />
      <h2 className="text-gray-800 text-lg font-semibold">{title}</h2>
    </div>
    <div className="p-4">
      {points.map((point, index) => (
        <ul className="mt-2 text-gray-600">
          <li key={index} className="flex items-baseline">
            •&nbsp;
            <span>{point}</span>
          </li>
        </ul>
      ))}
    </div>
  </div>
);

const Products = () => {
  return (
    <div className=" relative bg-[#caecdc]">
      <section className="product_banner hidden md:block relative">
        <div className="absolute bottom-10 left-0 m-5">
          <a
            href="https://flexioms.com/omnichannel/Landing/welcome.html"
            target="_blank"
            class="bg-[#263238] w-32 inline-flex items-center justify-center py-3 text-base font-medium text-center text-white rounded-lg dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800 absolute"
          >
            Visit Flexioms
          </a>
        </div>
      </section>
      <hr />
      <div className="bg-transparent flex flex-col items-center justify-center md:hidden">
        <img
          src={require("../Assets/logo_footer.png")}
          className="w-56 mt-20"
          alt=""
        />
        <div>
          <a
            href="https://flexioms.com/omnichannel/Landing/welcome.html"
            target="_blank"
            class="bg-[#263238] w-48 mt-5 inline-flex items-center justify-center py-3 text-base font-medium text-center text-white rounded-lg dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
          >
            Visit Flexioms
          </a>
        </div>
      </div>
      <section className="bg-[#caecdc] overflow-hidden md:h-[140vh]">
        <div class="text-center">
          <h1 className="text-3xl font-black text-gray-700 my-7">
            Product Features
          </h1>
        </div>
        <div>
          <div className="container mx-auto flex justify-evenly lg:absolute flex-wrap left-10">
            {featuresData.map((card, index) => (
              <Feature
                key={index}
                title={card.title}
                points={card.points}
                img={card.img}
              />
            ))}
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Products;
