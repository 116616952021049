import React from "react";
import ClientCarousel from "./Pause";

const Partnership = () => {
  return (
    <section id="partnership">
      <div>
        <div className="hidden md:block">
          <div className="flex justify-center items-center w-full mt-20">
            <div className="bg-transparent lg:bg-[#E0F4EC] m-8 ml-10 flex justify-center flex-col items-center w-[80%] lg:w-[85%] rounded-3xl">
              <h1 className="text-3xl font-black text-gray-700 my-7">Partnerships</h1>
              <div className="flex p-4 mb-12 justify-between flex-wrap">
                <div class="flex justify-center items-center flex-col h-96 bg-[#E0F4EC] w-80 rounded-lg shadow-2xl lg:bg-[#D6F3F6]">
                  <img
                    class="w-[60%] object-cover"
                    src={require("../Assets/nextuple_logo_vector (4).png")}
                    alt="Card 1"
                  />
                  <div class="p-4">
                    <p class="text-gray-700">Nextuple is a leading IT retail and supply chain solution and service provider based out of Boston, USA. Flexinovus is a strategic partner for  Nextuple across implementation and support services</p>
                  </div>
                </div>
                <div className="hidden sm:block">
                  <img class="w-full h-64 object-cover" src={require("../Assets/Partnership-bro.png")} className="w-128" alt="Card 1" />
                </div>
                {/* <!-- Card 2 --> */}
                {/* <div class="flex justify-center items-center flex-col h-96 bg-[#E0F4EC] w-64 rounded-lg shadow-lg lg:bg-[#D6F3F6] mb-4">
                <img
                  class="w-[60%] object-cover"
                  src={require("../Assets/cloud-innovate.png")}
                  alt="Card 1"
                />
                <div class="p-4">
                  <p class="text-gray-700">CloudInnov8.com is a Multi Cloud- Managed Services Provider i.e. for AWS, Azure, Google, Softlayer & Alibaba. We have partnered with them to provide all Infra, Devops and Cloud related services</p>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden">
          <ClientCarousel />
        </div>
      </div>
    </section>
  );
};

export default Partnership;
