import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Products from './Components/Products';
import Navbar from './Components/Navbar';
import Career from './Components/Career';
import BlogsContent from './Components/BlogsContent';

function App() {
  return (
    <div className="App overflow-hidden">
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/career" element={<Career />} />
          <Route exact path="/blog" element={<BlogsContent />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
