import React from "react";

const ClientCarousel = () => {
  
  return (
    <section className="partners testimonials_container m-10" id="Clients">
      <h1 className="text-3xl font-bold mb-10 text-center">Our Clients</h1>
      <div class="flex flex-col items-center justify-center h-96 bg-[#E0F4EC] w-full rounded-lg shadow-lg mb-4">
        <img
          class="w-[60%]"
          src={require("../Assets/nextuple_logo_vector (4).png")}
          alt="Card 1"
        />
        <div class="p-4">
          <p class="text-gray-700">
            Nextuple is a leading IT retail and supply chain solution and
            service provider based out of Boston, USA. Flexinovus is a strategic
            partner for Nextuple across implementation and support services
          </p>
        </div>
      </div>
    </section>
  );
};

export default ClientCarousel;
